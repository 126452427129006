import React, { Component } from "react"
import "./App.css"
import { Link } from "gatsby"
import MediaQuery from "react-responsive"
import ContactHeader from "../components/contactHeader"
import SEO from "../components/seo"
import { AiTwotonePhone, AiTwotoneEmail, AiOutlineTwitter, AiFillPhone, AiFillMail } from 'react-icons/ai';
export default class About extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show_overlay: false,
    }
  }

  render() {
    return (
      <div>
        <SEO title="Contact Us" />
        <MediaQuery maxDeviceWidth={1224}>
          <div style={{width: 50}}>
            <div style={{ position: "absolute",width:"100vw" }}>
              <div class="mobile-headerContainer">
                <a
                  href="#"
                  onClick={event => this.setState({ show_overlay: true })}
                >
                  <div class="mobile-headerOverlayButtonPlaceholder">
                  <img alt="menu-button" src={require('../images/menu_icon.png')} class="mobile-headerButton"/>
                  </div>
                </a>
                <Link to="/" style={{ textDecoration: "none" }}>
                  <text class="mobile-headerText">
                    <text style={{ color: "#FF4747" }}>Students</text>
                    <text style={{ color: "#000000" }}> For </text>
                    <text style={{ color: "#5793FA" }}>2020</text>
                  </text>
                </Link>
                <Link to="/map/" style={{ textDecoration: "none" }}>
                  <div class="mobile-headerVoteButton">
                    <text class="mobile-headerVoteButtonTexts">Vote</text>
                  </div>
                </Link>
              </div>

              <div
                class="mobile-about-bodyContainer"
                style={{
                  backgroundImage:
                    "url('https://images.pexels.com/photos/417054/pexels-photo-417054.jpeg?cs=srgb&dl=pexels-pixabay-417054.jpg&fm=jpg')",
                }}
              >
                {" "}
                {/* https://www.pexels.com/license/ image license */}
                <div class="mobile-about-darkenBackground">
                  <text class="mobile-about-titleText">Contact Us</text>
                  <div class="mobile-about-divider" />

                  <text
                    class="mobile-about-subtitleText"
                    style={{ marginTop: "16.87vh" }}
                  >
                    General Inquiries
                  </text>

                  <text
                    class="mobile-about-primaryText"
                    style={{ marginTop: "22.41vh" }}
                  >
                    Call Us
                  </text>
                  <text
                    class="mobile-about-secondaryText"
                    style={{ marginTop: "25.12vh" }}
                  >
                    (404)-987-1503
                  </text>
                  <text
                    class="mobile-about-primaryText"
                    style={{ marginTop: "22.41vh", marginLeft: "44.27vw" }}
                  >
                    Email Us
                  </text>
                  <text
                    class="mobile-about-secondaryText"
                    style={{ marginTop: "25.12vh", marginLeft: "44.27vw" }}
                  >
                    studentsfor2020@gmail.com
                  </text>
                </div>
              </div>

              <div class="mobile-footerContainer">
                <text class="mobile-footerText">
                  <text style={{ color: "#FF4747" }}>Students</text>
                  <text style={{ color: "#ffffff" }}> For </text>
                  <text style={{ color: "#5793FA" }}>2020</text>
                </text>
                <text class="mobile-footerSmallText">
                  2020 © All Rights Reserved
                </text>
              </div>
            </div>

            <div
              class="mobile-overlayContainer"
              style={{
                display: this.state.show_overlay === true ? "block" : "none",
              }}
            >
              <Link
                to="/"
                style={{
                  textDecoration: "none",
                  marginTop: "5.97vh",
                  position: "absolute",
                }}
              >
                <text class="mobile-overlayText">Home</text>{" "}
              </Link>
              <Link
                to="/about/"
                style={{
                  textDecoration: "none",
                  marginTop: "11.37vh",
                  position: "absolute",
                }}
              >
                <text class="mobile-overlayText">Contact Us</text>{" "}
              </Link>
              <Link
                to="/press/"
                style={{
                  textDecoration: "none",
                  marginTop: "16.77vh",
                  position: "absolute",
                }}
              >
                <text class="mobile-overlayText">Press</text>{" "}
              </Link>
              <Link
                to="/volunteer/"
                style={{
                  textDecoration: "none",
                  marginTop: "22.17vh",
                  position: "absolute",
                }}
              >
                <text class="mobile-overlayText">Volunteer</text>{" "}
              </Link>
              <Link
                to="/map/"
                style={{
                  textDecoration: "none",
                  marginTop: "27.57vh",
                  position: "absolute",
                }}
              >
                <text class="mobile-overlayText">Where To Vote</text>{" "}
              </Link>
              <Link to="/map/" style={{ textDecoration: "none" }}>
                <div class="mobile-overlayButton">
                  <text class="mobile-overlayButtonText">Vote</text>
                </div>
              </Link>
            </div>
            <div
              class="mobile-overlayDarken"
              onClick={event => this.setState({ show_overlay: false })}
              style={{
                display: this.state.show_overlay === true ? "block" : "none",
              }}
            />

            <text class="headerText">
              <Link to="/press/" style={{ textDecoration: "none" }}>
                <text style={{ marginRight: 32, color: "#5793FA" }}>Press</text>{" "}
              </Link>
              
              <Link to="/volunteer/" style={{ textDecoration: "none" }}>
                <text style={{ marginRight: 32, color: "#5793FA" }}>
                  Volunteer
                </text>{" "}
              </Link>
              <Link to="/about/" style={{ textDecoration: "none" }}>
                <text style={{ color: "#5793FA" }}>Contact Us</text>{" "}
              </Link>
            </text>
          </div>
        </MediaQuery>

        <MediaQuery minDeviceWidth={1224}>
          <div>
            <div class="topBar">
              <div class="logoDiv">
                <Link to="/" style={{ textDecoration: "none" }}>
                  <text class="students-for-2020">
                    <text style={{ color: "#FF4747" }}>Students</text>
                    <text style={{ color: "#000000" }}> For </text>
                    <text style={{ color: "#5793FA" }}>2020</text>
                  </text>
                </Link>
              </div>
              <div class="headerDiv">
                <Link to="/press/" style={{ textDecoration: "none" }}>
                  <text class="headerText">Press</text>
                </Link>
                
                <Link to="/volunteer/" style={{ textDecoration: "none" }}>
                  <text class="headerText">Volunteer</text>
                </Link>
                <Link to="/about/" style={{ textDecoration: "none" }}>
                  <text class="headerText">Contact Us</text>
                </Link>
              </div>
              <div class="headButtDiv">
                <a
                  class="headerButton"
                  href="/map/"
                  style={{ textDecoration: "none" }}
                >
                  <text class="headerButtonText">Where Should I Vote?</text>
                </a>
              </div>
            </div>  
            <div style={{ height: "82vh" }}>
              <div style={{ height: "74.44vh" }}>
                <div class="about-headerImage" style={{ height: "25.11vh" }}>
                  <ContactHeader>
                    <div class="about-flexContainerHeader">
                      <text class="about-headerText">Contact Us</text>
                    </div>
                  </ContactHeader>
                </div>
                <div style={{ position: "absolute", marginLeft: "38vw" }}>
                  <text class="about-titleText">GENERAL INQUERIES</text>
                  <div class="about-titleDivider" />
                  <div style={{ marginTop: "2vh" }}>
                    <div>
                      <div className={'subtitleTextWrapper'}>
                          <AiFillPhone className={"icon-about"}/>
                          <text class="about-subtitleText">Phone</text>
                      </div>
                      <text class="about-text" style={{ marginTop: "3.77vh" }}>
                        (470)-621-1343
                      </text>
                      <div className={'subtitleTextWrapper'}>
                          {/* <AiTwotoneEmail className={"icon-about"}/> */}
                          <AiFillMail className={"icon-about"}/>
                          <text
                            class="about-subtitleText">
                            Email
                          </text>
                      </div>
                      
                    </div>
                    <div>
                      <text
                        class="about-text"
                        style={{ marginTop: "3.77vh"}}
                      >
                        studentsfor2020@gmail.com
                      </text>
                    </div>
                  </div>
                </div>
               
              </div>
            </div>

            <div class="bottomBar">
              <Link to="/" style={{ textDecoration: "none" }}>
                <text class="students-for-2020">
                  <text style={{ color: "#FF4747" }}>Students</text>
                  <text style={{ color: "#ffffff" }}> For </text>
                  <text style={{ color: "#5793FA" }}>2020</text>
                </text>
              </Link>
              <div class="rightsReservedDiv">
                <text class="rightsReserved">2020 © All Rights Reserved</text>
              </div>
            </div>
          </div>
        </MediaQuery>
      </div>
    )
  }
}
